import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { Textarea } from '../components/ui/textarea';

const API_URL = process.env.REACT_APP_API_URL;

const determineRarity = (rate) => {
  if (rate >= 0.01) return 'Common';
  else if (rate >= 0.005) return 'Uncommon';
  else if (rate >= 0.0015) return 'Rare';
  else return 'Jackpot';
};

const rewardSets = {
  wanderBox: {
    rates: `0.13% 	Lady of Midnight's Cold Wings
0.13% 	Lady of Midnight's Bloody Wings
0.13% 	Lady of Midnight's Deep Wings
0.13% 	Lady of Midnight's Leathery Wings
0.13% 	Special Worldwide Witness Outfit (M)
0.13% 	Special Worldwide Wanderer Outfit (F)
0.19% 	Worldwide Witness Outfit (M)
0.19% 	Worldwide Wanderer Outfit (F)
0.22% 	Worldwide Witness Gloves (M)
0.22% 	Worldwide Wanderer Gloves (F)
0.22% 	Worldwide Witness Boots (M)
0.22% 	Worldwide Wanderer Boots (F)
0.19% 	Worldwide Witness Wig (M)
0.19% 	Worldwide Wanderer Wig (F)
0.19% 	Broad-brimmed Belted Hat
0.19% 	Slouchy Belted Beret
0.19% 	Worldwide Witness One-handed Sword Appearance Scroll
0.19% 	Worldwide Wanderer Two-handed Sword Appearance Scroll
0.19% 	Worldwide Witness Bow Appearance Scroll
0.19% 	Worldwide Wanderer Chain Blade Appearance Scroll
0.19% 	Spirit Transformation Liqueur (Plentiful Puffballs)
0.19% 	Spirit Transformation Liqueur (Black Rose)
0.19% 	Spirit Transformation Liqueur (Bright Butterfly Lure)
0.19% 	Spirit Transformation Liqueur (Dreamlike Butterfly Lure)
0.19% 	Spirit Transformation Liqueur (Brilliant Butterfly Lure)
0.19% 	Spirit Transformation Liqueur (Otherworldly Light)
0.19% 	Spirit Transformation Liqueur (Abyss Inferno)
0.19% 	Blazing Assault Slash Effect Change Card (Explosive Impact) Coupon
0.19% 	Spellwalk Effect Change Card (Blossoming Step) Coupon
0.19% 	Spinning Uppercut Effect Change Card (Red Lightning) Coupon
0.19% 	Battlefield Overture Effect Change Card (Cool Raving Rabbit) Coupon
0.19% 	Spellwalk Effect Change Card (Shadow Blossoming Step) Coupon
0.19% 	Thunder Effect Change Card (Dark Whirlwind) Coupon
0.19% 	Dual Gun Mastery Effect Change Card (Hyperlight Railgun)
0.19% 	Bullet Storm Effect Change Card (Hyperlight Railgun)
0.19% 	Bullet Slide Effect Change Card (Hyperlight Railgun)
0.08% 	Hillwen Engineer's Emerald Golem
0.12% 	Hillwen Engineer's Research Group (for 4)
0.12% 	Black Bag (10X17) Coupon
0.12% 	Special Midnight Investigator Wear (F)
0.12% 	Special Midnight Investigator Wear (M)
0.12% 	Special Hillwen Engineer's Outfit (F)
0.12% 	Special Hillwen Engineer's Outfit (M)
0.44% 	Midnight Investigator Wear (F)
0.44% 	Midnight Investigator Wear (M)
0.73% 	Midnight Investigator Gloves (F)
0.73% 	Midnight Investigator Gloves (M)
0.73% 	Midnight Investigator Shoes (F)
0.73% 	Midnight Investigator Shoes (M)
0.73% 	Midnight Investigator Wig (F)
0.73% 	Midnight Investigator Wig (M)
0.73% 	Hillwen Engineer's Outfit (F)
0.73% 	Hillwen Engineer's Outfit (M)
0.73% 	Hillwen Engineer's Heavy-duty Gloves (F)
0.73% 	Hillwen Engineer's Heavy-duty Glove (M)
0.73% 	Cherished Bunny Doll
0.73% 	Charming Bunny Doll
0.73% 	Charming Teddy Bear
0.73% 	Cherished Teddy Bear
0.44% 	Glass Trim Veiling Band and Earrings (Face Accessory Slot Exclusive)
0.73% 	Hillwen Engineer's Earrings (Face Accessory Slot Exclusive)
0.73% 	Eye Patch and Cheek Bandage (Face Accessory Slot Exclusive)
0.73% 	Hillwen Eroded Skin Mask (Face Accessory Slot Exclusive)
0.73% 	Raving Rabbit Eyepatch (Face Accessory Slot Exclusive)
0.58% 	Blue Hydrangea Veil (Face Accessory Slot Exclusive)
0.73% 	Homestead Sunken Clock A
0.73% 	Homestead Sunken Clock B
0.73% 	Homestead Hillwen Engineer's Tool Rack
0.44% 	Homestead Hillwen Ore
1.17% 	Hillwen Engineer's Boots (F)
1.17% 	Hillwen Engineer's Legwraps (M)
1.46% 	Hillwen Engineer's Wig (F)
1.46% 	Hillwen Engineer's Wig (M)
1.46% 	Hillwen Engineer's Wig and Goggles (F)
1.46% 	Hillwen Engineer's Wig and Goggles (M)
1.46% 	Dark Crescent Star Halo
1.46% 	Gold Crescent Star Halo
1.46% 	Blue Crescent Star Halo
1.46% 	Eluned Pitch Black Eye Patch (F) (Face Accessory Slot Exclusive)
1.31% 	Watchwork Monocle (Face Accessory Slot Exclusive)
1.31% 	Black Rose Left Earring and Monocle (Face Accessory Slot Exclusive)
1.46% 	Black Rose Right Earring (Face Accessory Slot Exclusive)
1.31% 	Magic Librarian Lanyard Reading Glasses (Face Accessory Slot Exclusive)
1.46% 	Star-Studded Magician Mask (Face Accessory Slot Exclusive)
1.46% 	Steampunk Monocle (Face Accessory Slot Exclusive)
1.31% 	Homestead Dark Barrier Stone Pillar
1.31% 	Homestead Hot Spring Sozu Fountain
1.17% 	Homestead Sleeping Frost Greatsword
1.46% 	Winter Royal Snow Spell Book
1.46% 	Sacred Light Spellbook
1.46% 	Altam Fixed Dye Ampoule Set
1.46% 	Cethlenn Fixed Dye Ampoule Set
1.46% 	Vayne Fixed Dye Ampoule Set
1.46% 	Name/Chat Fixed Color Change Potion
1.46% 	Name/Chat Fixed Color Change Potion
1.46% 	Name/Chat Fixed Color Change Potion
1.55% 	Speed Walk Potion 40% (30 min)
1.55% 	Light Physical Power Potion (2 Hours)
1.55% 	Light Magic Power Potion (2 Hours)
1.55% 	Fixed Color Dye Ampoule (#FEFEFE)
1.55% 	Fixed Color Dye Ampoule (#282828)
1.55% 	Fixed Color Dye Ampoule (#F2A35E)
1.55% 	Fixed Color Dye Ampoule (#A6523F)
1.55% 	Fixed Color Dye Ampoule (#293A5C)
1.55% 	Fixed Color Dye Ampoule (#353240)
1.55% 	Fixed Color Dye Ampoule (#84041A)
1.55% 	Fixed Color Dye Ampoule (#14141A)
1.55% 	Fixed Color Dye Ampoule (#4A3230)
1.55% 	Natural Shield Training Potion
1.55% 	Lightning Shield Training Potion
1.55% 	Ice Shield Training Potion
1.55% 	Fire Shield Training Potion
1.55% 	Respite Training Potion
1.55% 	Collapse Gesture Card
1.55% 	Defend Gesture Card
1.55% 	Oath Gesture Card
1.55% 	Homestead Cat Pottery Lamp
1.55% 	Homestead Blue Goldfish Lamp
1.55% 	Homestead Small Goldfish Lamp
1.55% 	Homestead Golden Sprout (x5)`,
    costs: {
      1: 1.5,
      11: 15,
      45: 57.5
    },
    batches: [1, 11, 45]
  },
  berchelBox: {
    rates: `0.13% 	Elegant Berchel Prevail Overcoat
0.13% 	Elegant Berchel Prevail Topcoat
0.13% 	Pure Berchel Prevail Overcoat
0.13% 	Pure Berchel Prevail Topcoat
0.13% 	Somber Berchel Prevail Overcoat
0.13% 	Somber Berchel Prevail Topcoat
0.13% 	Special Berchel Prevail Suit (M)
0.13% 	Special Berchel Prevail Dress (F)
0.16% 	Berchel Prevail Suit (M)
0.16% 	Berchel Prevail Dress (F)
0.18% 	Berchel Prevail Timepiece (M)
0.18% 	Berchel Prevail Timepiece (F)
0.18% 	Berchel Prevail Shoes (M)
0.18% 	Berchel Prevail Shoes (F)
0.15% 	Berchel Prevail Beret
0.15% 	Reichel Prevail Beret
0.15% 	Berchel Prevail Cottonwood Bouquet Appearance Scroll
0.15% 	Reichel Prevail Cottonwood Bouquet Appearance Scroll
0.15% 	Berchel Prevail Control Bars Appearance Scroll
0.15% 	Reichel Prevail Control Bars Appearance Scroll
0.15% 	Berchel Prevail Kriegsmesser Appearance Scroll
0.15% 	Reichel Prevail Kriegsmesser Appearance Scroll
0.13% 	Berchel Prevail Blossombough Appearance Scroll
0.13% 	Reichel Prevail Blossombough Appearance Scroll
0.16% 	Final Hit Effect Change Chard (Berchel Butterflies) Coupon
0.16% 	Final Strike Effect Change Chard (Berchel Butterflies) Coupon
0.16% 	Final Shot Effect Change Chard (Berchel Butterflies) Coupon
0.16% 	Final Hit Effect Change Chard (Reichel Butterflies) Coupon
0.16% 	Final Strike Effect Change Chard (Reichel Butterflies) Coupon
0.16% 	Final Shot Effect Change Chard (Reichel Butterflies) Coupon
0.16% 	Spirit Transformation Liqueur (Raging Thundersurge)
0.16% 	Spirit Transformation Liqueur (Imperial Thundersurge)
0.16% 	Spirit Transformation Liqueur (Oblivion Thundersurge)
0.16% 	Spirit Transformation Liqueur (Violet Floral Temptation)
0.16% 	Spirit Transformation Liqueur (Golden Floral Temptation)
0.10% 	Carasek's Bag (10x16) Coupon
0.16% 	Secret Forest Wildflower Two-handed Sword
0.16% 	Enigmatic Quill
0.20% 	Floral Flourish Rapier
0.16% 	Special Royal Prestige Academy Uniform (F)
0.16% 	Special Royal Prestige Academy Uniform (M)
0.16% 	Special National Prestige Academy Uniform (F)
0.16% 	Special National Prestige Academy Uniform (M)
0.16% 	Private Academy Junior Uniform (F)
0.16% 	Private Academy Junior Uniform (M)
0.16% 	Private Academy Senior Uniform (F)
0.16% 	Private Academy Senior Uniform (M)
0.89% 	Royal Prestige Academy Uniform (F)
0.82% 	Royal Prestige Academy Uniform (M)
0.82% 	Royal Prestige Academy Wig (F)
0.82% 	Royal Prestige Academy Wig and Hat (F)
0.82% 	National Prestige Academy Wig (F)
0.82% 	National Prestige Academy Wig and Hat (F)
0.82% 	Royal Prestige Academy Gloves (F)
0.82% 	Royal Prestige Academy Gloves (M)
0.23% 	National Prestige Academy Gloves (F)
0.23% 	National Prestige Academy Gloves (M)
0.82% 	Royal Prestige Academy Shoes (F)
0.82% 	Royal Prestige Academy Shoes (M)
0.82% 	Private Academy Riding Uniform (F)
0.82% 	Private Academy Riding Uniform (M)
0.82% 	Private Academy Braided Bangs Wig (F)
0.82% 	Private Academy Braided Bangs Wig and Ornament (F)
0.66% 	Private Academy Side Twist Wig (F)
0.66% 	Private Academy Side Twist Wig and Ornament (F)
0.82% 	Private Academy Low Bun Wig (F)
0.66% 	Private Academy Low Bun Wig and Riding Hat (F)
0.66% 	Private Academy Layered Straight Cut Wig (M)
0.66% 	Elegant Riding Crop
0.66% 	Classic Broom
0.66% 	Classic Tea Set
0.82% 	Fox Face Mask (F) (Face Accessory Slot Exclusive)
0.82% 	Bodacious Party Facial Bandage (Face Accessory Slot Exclusive)
0.82% 	Cat Face Mask (Face Accessory Slot Exclusive)
0.82% 	Cheek Bandage (Face Accessory Slot Exclusive)
0.82% 	Jeweled Forehead Decoration (Face Accessory Slot Exclusive)
1.32% 	National Prestige Academy Uniform (F)
1.32% 	National Prestige Academy Uniform (M)
1.32% 	Royal Prestige Academy Wig (M)
1.32% 	Royal Prestige Academy Wig and Hat (M)
1.32% 	National Prestige Academy Wig (M)
1.32% 	National Prestige Academy Wig and Hat (M)
1.32% 	National Prestige Academy Shoes (F)
1.32% 	National Prestige Academy Shoes (M)
1.32% 	Private Academy Wavy Layered Medium Cut Wig (M)
1.32% 	Private Academy Parted Layered Wavy Wig (M)
1.32% 	Private Academy Parted Layered Wavy and Riding Hat (M)
1.32% 	Private Academy Riding Hat (F)
1.32% 	Private Academy Riding Hat (M)
1.32% 	Private Academy Shoes (F)
1.32% 	Private Academy Shoes (M)
1.32% 	Private Academy Loafers (F)
1.32% 	Private Academy Loafers (M)
1.32% 	Private Academy Riding Boots (F)
1.32% 	Private Academy Riding Boots (M)
1.32% 	Private Academy Riding Gloves
0.99% 	Frost Lyre
1.15% 	Melodious Music Box
1.15% 	Lovely Winter Piano
1.15% 	Milky Way Piano
1.15% 	Dark Divination Piano
0.76% 	Dogma of Warding
0.76% 	Dogma of Binding
0.76% 	Dogma of Smiting
1.68% 	Speed Walk Potion 40% (30 min)
1.68% 	Light Physical Power Potion (2 Hours)
1.68% 	Light Magic Power Potion (2 Hours)
1.68% 	Stardust Direct Dye Ampoule
1.68% 	Direct Dye Ampoule
1.68% 	Direct Metal Dye Ampoule
1.68% 	Direct Magical Instrument Dye
1.68% 	Direct Pet Dye Ampoule
1.68% 	Burlywood Beige Magic Ink (x5)
1.68% 	Cherry Blossom Pink Magic Ink (x5)
1.68% 	Forest Green Magic Ink (x5)
1.68% 	Ocean Blue Magic Ink (x5)
1.68% 	Magenta Magic Ink (x5)
1.68% 	Homestead Luna Fairy Street Light (Orange)
1.68% 	Homestead Luna Fairy Street Light (Green)
1.68% 	Homestead Luna Fairy Street Light (Blue)
1.68% 	Homestead Luna Fairy Street Light (Purple)
1.68% 	Bored Gesture Card
1.68% 	Coy Gesture Card
1.68% 	Chat Gesture Card
1.68% 	Ancient Magic Powder (x2)
1.68% 	Brass Grandmaster Certificate`,
    costs: {
      1: 1.5,
      11: 15,
      45: 57.5
    },
    batches: [1, 11, 45]
  }
};

const GachaSimulator = () => {
  const [selectedSet, setSelectedSet] = useState('wanderBox');
  const [prizeInput, setPrizeInput] = useState(rewardSets.wanderBox.rates);
  const [costs, setCosts] = useState(rewardSets.wanderBox.costs);
  const [batches, setBatches] = useState(rewardSets.wanderBox.batches);
  const [rewards, setRewards] = useState([]);
  const [awardedItems, setAwardedItems] = useState({});
  const [totalDraws, setTotalDraws] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [filter, setFilter] = useState('');
  const [visitorCount, setVisitorCount] = useState(0);
  const [globalSpent, setGlobalSpent] = useState(0);

  const fetchStats = async () => {
    try {
      const response = await fetch(`${API_URL}/api/stats`);
      const data = await response.json();
      setVisitorCount(data.visitors);
      setGlobalSpent(data.totalSpent);
    } catch (error) {
      console.error('Failed to fetch stats:', error);
    }
  };

  useEffect(() => {
    const initVisitor = async () => {
      try {
        const response = await fetch(`${API_URL}/api/stats`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ type: 'visitor' }),
        });
        const data = await response.json();
        setVisitorCount(data.visitors);
        setGlobalSpent(data.totalSpent);
      } catch (error) {
        console.error('Failed to increment visitor:', error);
      }
    };
    initVisitor();
  }, []);

  useEffect(() => {
    parseRewards();
  }, [prizeInput]);

  useEffect(() => {
    setPrizeInput(rewardSets[selectedSet].rates);
    setCosts(rewardSets[selectedSet].costs);
    setBatches(rewardSets[selectedSet].batches);
  }, [selectedSet]);

  const parseRewards = () => {
    const parsedRewards = prizeInput.split('\n').map(line => {
      const parts = line.split(/\t|(?<=\%)\s+/);
      const rateStr = parts[0].trim();
      const item = parts[1].trim();
      const rate = parseFloat(rateStr.replace('%', '').trim()) / 100;
      return { item, rate };
    });
    setRewards(parsedRewards);
  };

  const drawItems = async (drawCount) => {
    const newAwardedItems = { ...awardedItems };
    for (let i = 0; i < drawCount; i++) {
      const random_number = Math.random();
      let cumulativeRate = 0;
      for (const reward of rewards) {
        cumulativeRate += reward.rate;
        if (random_number < cumulativeRate) {
          if (reward.item in newAwardedItems) {
            newAwardedItems[reward.item].count++;
          } else {
            newAwardedItems[reward.item] = {
              count: 1,
              rarity: determineRarity(reward.rate)
            };
          }
          break;
        }
      }
    }
    setAwardedItems(newAwardedItems);
    setTotalDraws(prevTotalDraws => prevTotalDraws + drawCount);

    const batchCost = costs[drawCount] || (1.5 * drawCount);
    setTotalCost(prevTotalCost => prevTotalCost + batchCost);
    
    try {
      const response = await fetch(`${API_URL}/api/stats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          type: 'spent',
          amount: batchCost 
        }),
      });
      const data = await response.json();
      setGlobalSpent(data.totalSpent);
    } catch (error) {
      console.error('Failed to update total spent:', error);
    }
  };

  const filteredItems = Object.entries(awardedItems)
    .filter(([item, data]) => filter === '' || data.rarity === filter)
    .sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <div className="p-4 bg-background text-foreground min-h-screen">
      <Card className="mb-4">
        <CardHeader>Dani's Gacha Sim</CardHeader>
        <CardContent>
          <h2 className="mb-2">
            <a
              href="https://repo.danii.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Repo of my dumb projects
            </a>
          </h2>
          <h4 className="mb-2">To change the rates/items, copy from the RNG rates table that is linked in every gacha</h4>
          <h4 className="mb-2">Copy only the %s and the names, leave the headers out.</h4>
          <Textarea
            value={prizeInput}
            onChange={(e) => setPrizeInput(e.target.value)}
            className="w-full mb-4"
          />
          <div className="mb-4">
            {batches.map(batchSize => (
              <Button key={batchSize} onClick={() => drawItems(batchSize)} className="mr-2">
                Draw {batchSize}
              </Button>
            ))}
          </div>
          <div className="mt-4 space-y-2">
            <div>Total Visitors: {visitorCount}</div>
            <div>Your Draws: {totalDraws} (${totalCost.toFixed(2)})</div>
            <div>Global Amount Spent: ${globalSpent.toFixed(2)}</div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>Choose Reward Set</CardHeader>
        <CardContent>
          <div className="mb-4">
            <Button onClick={() => setSelectedSet('wanderBox')} className="mr-2">Worldwide Wanderer Box</Button>
            <Button onClick={() => setSelectedSet('berchelBox')} className="mr-2">Berchel Prevail Box</Button>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>Results</CardHeader>
        <CardContent>
          <div className="mb-4">
            <Button onClick={() => setFilter('')} className="mr-2">All</Button>
            <Button onClick={() => setFilter('Common')} className="mr-2">Common</Button>
            <Button onClick={() => setFilter('Uncommon')} className="mr-2">Uncommon</Button>
            <Button onClick={() => setFilter('Rare')} className="mr-2">Rare</Button>
            <Button onClick={() => setFilter('Jackpot')}>Jackpot</Button>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Prize</TableHead>
                <TableHead>Total</TableHead>
                <TableHead>Rarity</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredItems.map(([item, data]) => (
                <TableRow key={item}>
                  <TableCell>{item}</TableCell>
                  <TableCell>{data.count}</TableCell>
                  <TableCell>{data.rarity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default GachaSimulator;
